export function capitalize(input) {
  return `${input.slice(0, 1).toUpperCase()}${input.slice(1)}`;
}

export function alphabeticSort(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function numericSort(a, b) {
  return a - b;
}

export function selectSort(prop) {
  switch (prop) {
    case 'timestamp':
      return numericSort;
    default:
      return alphabeticSort;
  }
}
