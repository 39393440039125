import React from 'react';
import { db } from '../../firebase/firestore';
import { capitalize, selectSort } from './helpers';

const defaultSchema = ['name', 'email', 'timestamp'];

export default class FormDisplay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.startListener = this.startListener.bind(this);
    this.stopListener = this.stopListener.bind(this);
    this.restartListener = this.restartListener.bind(this);
    this.generateGridStyle = this.generateGridStyle.bind(this);
    this.sortRegistrants = this.sortRegistrants.bind(this);
    this.parseRegistrants = this.parseRegistrants.bind(this);
    // this.downloadForm = this.downloadForm.bind(this);
    this.state = { registrants: [], sortBy: 'timestamp' };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.restartListener();
    }
  }

  componentWillUnmount() {
    this.stopListener();
    this.setState({ registrants: [] });
  }

  restartListener() {
    this.stopListener();
    this.setState({ registrants: [] }, this.startListener);
  }

  startListener() {
    const { id, schema = defaultSchema } = this.props;
    const { sortBy } = this.state;

    if (id) {
      const registrantsRef = db
        .collection('forms')
        .doc(id)
        .collection('registrants');

      this.unsubscribe = registrantsRef.onSnapshot(snapshot => {
        let { registrants } = this.state;
        const newRegistrants = [];
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            newRegistrants.push(change.doc.data());
          }
          if (change.type === 'removed') {
            registrants = registrants.filter(registrant => {
              return registrant.email !== change.doc.data().email;
            });
          }
          // add update handler
        });

        // sort if sortBy is a valid part of the schema
        if (schema && schema.includes(sortBy)) {
          return this.sortRegistrants(sortBy, [...registrants, ...newRegistrants]);
        }

        // otherwise, just save registrants to state
        return this.setState({ registrants: [...registrants, ...newRegistrants] });
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generateGridStyle(schema) {
    if (schema) {
      // add 1 for the index column
      const cols = schema.length + 1;
      return {
        gridTemplateColumns: `repeat(${cols}, auto)`,
      };
    }
    return {};
  }

  /* Optionally pass in registrants to sort, or take them from state
   * @param {String} prop 
   * @param {Array} updatedRegistrants 
   */
  sortRegistrants(prop, updatedRegistrants) {
    console.log('sorting by', prop);
    const registrants = updatedRegistrants || this.state.registrants;
    const sortingFn = selectSort(prop);

    const sortedRegistrants = registrants.slice(0).sort(function(a, b) {
      const valueA = a[prop] || 'z';
      const valueB = b[prop] || 'z';
      return sortingFn(valueA, valueB);
    });

    this.setState({ registrants: sortedRegistrants, sortBy: prop });
  }

  parseRegistrants() {
    const { schema = defaultSchema } = this.props;
    const { registrants } = this.state;

    const Registrants = [];
    const registrantsExportArray = [];

    registrants.forEach((registrant, index) => {
      let registrantString = `${index + 1},`;

      // add an index column before users
      const userCountItem = (
        <div key={`${registrant.email}-index`} className="admin--display-field">
          {index + 1}
        </div>
      );

      Registrants.push(userCountItem);

      schema.forEach(field => {
        // if timestamp, parse to locale string, otherwise display field value if it exists
        const fieldValue =
          field === 'timestamp' && registrant[field]
            ? new Date(registrant[field]).toLocaleString()
            : registrant[field] || '';
        const item = (
          <div key={`${registrant.email}-${field}`} className="admin--display-field">
            {fieldValue}
          </div>
        );
        Registrants.push(item);
        const commaEscape = /,/.test(fieldValue) ? `"${fieldValue}"` : fieldValue;
        registrantString += `${commaEscape},`;
      });
      registrantsExportArray.push(registrantString);
    });

    console.log('prepped for d/l');
    console.log(Registrants, registrantsExportArray);

    return { Registrants, registrantsExportArray };
  }

  stopListener() {
    try {
      this.unsubscribe();
    } catch (err) {
      // console.error(err);
    }
  }

  // downloadForm(exportString = '') {
  //   console.log('downloading form...');
  //   const csv = `data:text/csv;charset=utf-8,${exportString}`;
  //   const encodedList = encodeURI(csv);
  //   // console.log(csv);
  //   window.open(encodedList);
  // }

  render() {
    const { schema = defaultSchema, id } = this.props;
    const { sortBy } = this.state;
    const headersExportArray = [];
    // const registrantsArray = [];

    if (!id) return null;
    const gridStyle = this.generateGridStyle(schema);
    const Headers = ['#', ...schema].map(header => {
      headersExportArray.push(`${capitalize(header)}\t`);
      return (
        <div
          key={header}
          className="admin--display-header admin--display-field"
          onClick={e => this.sortRegistrants(header)}
          data-selected={header === sortBy}
        >
          {capitalize(header)}
        </div>
      );
    });

    const { Registrants, registrantsExportArray } = this.parseRegistrants();

    const exportString =
      headersExportArray
        .map(el => {
          const trimmed = el.trim();
          if (trimmed === '#') return 'Number';
          return trimmed;
        })
        .join(',') +
      '\n' +
      registrantsExportArray.join('\n');
    // console.log('Export string:', exportString);
    const csv = encodeURI(`data:text/csv;charset=utf-8,${exportString}`);
    // const csv = encodeURI(`data:text/csv;charset=utf-8,${`test,test,tset`}`);
    console.log('csv:', csv);

    return (
      <React.Fragment>
        <div style={{ marginTop: '1.5rem' }}>
          <a
            href={csv}
            target="_blank"
            rel="noopener noreferrer"
            download={`${id} list.csv`}
            // type="button"
            className="admin--download-button"
            // onClick={() => {
            //   console.log('opening', csv);
            //   console.log(window);
            //   window.open(csv);
            // }}
          >
            Download CSV of Registrants
          </a>
        </div>
        <main className="admin--display-wrapper">
          <div style={gridStyle} className="admin--grid-container">
            {Headers}
            {Registrants}
          </div>
        </main>
      </React.Fragment>
    );
  }
}
