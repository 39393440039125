import React from 'react';
import Layout from '../../components/CleanLayout';
import SigninController from '../../components/login/SigninController.container';
import Signin from '../../components/login/Signin.container';
import SignoutButton from '../../components/login/SignoutButton';
import FormSelector from '../../components/admin/FormSelector';

import styles from '../../styles/admin.scss';

const METADATA = [
  {
    name: 'description',
    content: 'Manage registration list',
  },
  {
    name: 'keywords',
    content: 'Subtle Medical, admin, registration',
  },
];

const IndexPage = () => (
  <Layout pageTitle="Form Admin | Subtle Medical" metadata={METADATA}>
    <SigninController />
    <SignoutButton />
    <h1 className="admin--title">Subtle Medical Forms</h1>
    <FormSelector />
    <Signin />
  </Layout>
);

export default IndexPage;
