import React from 'react';
import { connect } from 'react-redux';
import { db } from '../../firebase/firestore';
import FormDisplay from './FormDisplay';

const mapStateToProps = (state, ownProps) => ({
  signedIn: state.user.signedIn,
  isAuthorized: state.user.isAuthorized,
});

function getDropdownList() {
  const formsRef = db.collection('forms');

  return formsRef
    .get()
    .then(formsSnapshot => {
      // console.log('getting dropdown list');
      const dropdownList = [];
      formsSnapshot.forEach(form => {
        const { name, schema, isArchived } = form.data();

        if (!isArchived) {
          const formMetadata = {
            id: form.id,
            name,
            schema,
          };
          dropdownList.push(formMetadata);
        }
      });
      return dropdownList;
    })
    .catch(error => null);
}

const defaultState = {
  id: null,
  name: null,
  schema: null,
  optionsArray: null,
  formsDictionary: null,
};

class FormSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    if (props.isAuthorized) this.dropdownListPromise = getDropdownList();
    this.state = defaultState;
  }

  componentDidMount() {
    const { isAuthorized } = this.props;
    // console.log('mounting form selector');
    if (isAuthorized) this.renderOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // authorization change
    if (prevProps.isAuthorized !== this.props.isAuthorized) {
      this.dropdownListPromise = getDropdownList();
      this.renderOptions();
    }
  }

  changeHandler(e) {
    e.stopPropagation();
    const formName = e.target.value;
    if (formName === '') return this.setState({ id: null, name: null, schema: null });
    const { id, name, schema } = this.state.formsDictionary[formName];
    this.setState({ id, name, schema });
  }

  renderOptions() {
    // console.log('rendering options');

    this.dropdownListPromise
      .then(dropdownList => {
        const values = Object.values(dropdownList);
        const optionsArray = [];
        const formsDictionary = {};
        values.forEach(form => {
          const { id, name = id, schema } = form;
          // console.log('isArchived:', isArchived);
          // // filter out old forms
          // if (!isArchived) {
          const option = <option key={id}>{name}</option>;
          optionsArray.push(option);
          formsDictionary[name] = { id, name, schema };
          // }
        });

        this.setState({ optionsArray, formsDictionary });
      })
      .catch(err => null);
  }

  render() {
    const { signedIn, isAuthorized } = this.props;
    if (signedIn !== true) return null;
    if (!isAuthorized) {
      return (
        <div className="admin--no-permissions">You don't have permissions to access this page.</div>
      );
    }
    const { optionsArray, name, id, schema } = this.state;

    return (
      <div className="admin--form-container">
        <label className="admin--form-label" htmlFor="form">
          Select a Form
        </label>
        <select
          name="form"
          className="admin--form-selector"
          type="selection"
          onChange={this.changeHandler}
        >
          <option />
          {optionsArray}
        </select>
        <FormDisplay id={id} name={name} schema={schema} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(FormSelector);
